<template>
  <v-container fluid class="fill-height">
    <v-row justify="center" class="mt-5 fill-height">
      <v-col md="4" sm="8" class="px-6">
        <v-form ref="loginForm" v-model="doValid" lazy-validation>
          <v-card outlined class="card-bg-none card-border-none">
            <v-card-title
              class="justify-center headline"
              v-text="title"
            ></v-card-title>
            <v-spacer />
            <v-card-subtitle
              class="text-center text-h6 green--text text--darken-4"
              v-text="formVars.isRegister ? '會員註冊' : '會員登入'"
            ></v-card-subtitle>
            <v-spacer />
            <v-card-text class="px-2 pb-0">
              <v-text-field
                v-model="formVars.email.value"
                :rules="formVars.email.rules"
                label="帳號"
                dense
                filled
                required
                @keypress.enter.prevent="onSubmit()"
              ></v-text-field>
              <v-text-field
                v-model="formVars.password.value"
                :type="'password'"
                label="密碼"
                :rules="formVars.password.rules"
                dense
                filled
                required
                @keypress.enter.prevent="onSubmit()"
              ></v-text-field>
              <template v-if="formVars.isRegister">
                <v-row dense>
                  <v-col>
                    <v-card-text class="pa-0">
                      <v-chip color="grey lighten-3" label>姓名</v-chip>
                    </v-card-text>
                    <v-divider />
                    <v-flex class="d-flex flex-row mt-3">
                      <v-text-field
                        v-model="formVars.mem_name.value.surname"
                        :rules="formVars.mem_name.rules.surname"
                        label="姓"
                        dense
                        required
                      ></v-text-field>
                      <v-spacer class="mx-2" />
                      <v-text-field
                        v-model="formVars.mem_name.value.givenname"
                        :rules="formVars.mem_name.rules.lagivenname"
                        label="名"
                        dense
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-card-text class="pa-0">
                      <v-chip color="grey lighten-3" label>{{
                        formVars.gender.label
                      }}</v-chip>
                    </v-card-text>
                    <v-divider />
                    <v-radio-group
                      v-model="formVars.gender.value"
                      row
                      :rules="formVars.gender.rules"
                      required
                    >
                      <v-radio label="女" value="F"></v-radio>
                      <v-radio label="男" value="M"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <!-- <v-card-text class="pa-0">
                      <v-chip color="grey lighten-3" label>{{
                        formVars.residence.label
                      }}</v-chip>
                    </v-card-text> -->
                    <v-divider />
                    <v-card outlined class="card-border-none">
                      <!-- 居住地 -->
                      <!-- <v-card-text class="pa-0">
                        <v-container fluid class="px-0">
                          <v-row dense>
                            <v-col>
                              <AddrInput
                                :initVars="formVars.residence"
                                :addrOpts="addrOpts"
                                v-on:update:setAddrInputValue="
                                  setFormInputValue
                                "
                              />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text> -->
                      <!-- 授權同意 -->
                      <v-card-text class="pa-0">
                        <v-col>
                          <v-checkbox
                            v-model="formVars.profile_authority.value"
                            :label="formVars.profile_authority.label"
                            :rules="formVars.profile_authority.rules"
                            required
                          ></v-checkbox>
                        </v-col>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-container>
                <v-row align="center" justify="space-between">
                  <v-btn
                    align-self="start"
                    rounded
                    color="success"
                    min-width="48%"
                    @click="onSubmit()"
                  >
                    <v-icon left> mdi-location-enter </v-icon>
                    送出
                  </v-btn>
                  <v-btn
                    outlined
                    rounded
                    color="success"
                    min-width="48%"
                    @click="onReset()"
                  >
                    <v-icon left> mdi-autorenew </v-icon>
                    重填
                  </v-btn>
                </v-row>
                <template v-if="!formVars.isRegister">
                  <v-row dense>
                    <v-col>
                      <v-checkbox
                        v-model="formVars.remember.value"
                        :label="'儲存登入狀態'"
                        color="success"
                      ></v-checkbox>
                    </v-col>
                    <v-col align-self="center" class="text-right">
                      <v-hover v-slot="{ hover }">
                        <v-chip
                          outlined
                          :color="hover ? 'orange darken-3' : 'warning'"
                          style="border: 0px"
                          @click="$router.push('/member/resetPWD')"
                        >
                          <v-icon left> mdi-lock-reset </v-icon>
                          忘記密碼？
                        </v-chip>
                      </v-hover>
                    </v-col>
                  </v-row>
                  <v-divider class="mb-4" />
                  <v-row align="center" justify="space-around" class="mt-2">
                    <v-hover v-slot="{ hover }">
                      <v-btn
                        outlined
                        rounded
                        :color="hover ? 'primary' : 'grey'"
                        min-width="100%"
                        @click="formVars.isRegister = true"
                      >
                        <v-icon left> mdi-account-plus-outline </v-icon>
                        加入會員
                      </v-btn>
                    </v-hover>
                  </v-row>
                </template>
                <template v-else>
                  <v-divider class="mt-8 mb-4" />
                  <v-row align="center" justify="space-around" class="mt-2">
                    <v-hover v-slot="{ hover }">
                      <v-btn
                        outlined
                        rounded
                        :color="hover ? 'primary' : 'grey'"
                        min-width="100%"
                        @click="formVars.isRegister = false"
                      >
                        <v-icon left> mdi-reply-outline </v-icon>
                        返回登入
                      </v-btn>
                    </v-hover>
                  </v-row>
                </template>
              </v-container>
            </v-card-actions>
          </v-card>
        </v-form>
        <!-- dialog -->
        <MsgDialog :dialogProps="dialog">
          <template v-slot:custom-card-actions>
            <v-btn
              color="green darken-1"
              outlined
              @click="clickDialogBtn('home')"
              v-if="dialog.btns.home"
              >回首頁</v-btn
            >
            <v-btn
              color="green darken-1"
              outlined
              @click="clickDialogBtn('login')"
              v-if="dialog.btns.login"
              >返回登入</v-btn
            >
            <v-btn
              color="green darken-1"
              outlined
              @click="clickDialogBtn('close')"
              v-if="dialog.btns.close"
              >關閉</v-btn
            >
            <v-btn
              color="green darken-1"
              outlined
              @click="clickDialogBtn('abort')"
              v-if="dialog.btns.abort"
              >跳出</v-btn
            >
          </template>
        </MsgDialog>
        <!-- dialog for processing end -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'; // namespacedHelpers
import { apiMemberPostRegister } from '@api/member.js';
// import calAddress from '@util/common/calAddress.js';
// import AddrInput from '@component/form/AddrInput.vue';
import MsgDialog from '@component/dialog/MsgDialog';

export default {
  components: { MsgDialog }, //, AddrInput },
  props: ['title'],
  data () {
    return {
      dialog: {
        init: false,
        active: false,
        isClose: false, // 關閉訊息窗
        title: '',
        msg: '',
        process: false,
        persistent: true,
        btns: {
          close: false, // 關閉訊息窗
          login: false, // 返回會員燈入頁
          home: false, // 返回平台首頁
          abort: false // 跳出目前視窗
        }
      },
      doValid: true,
      formVars: {
        // username: { id: 'username', label: '帳號', value: '', rules: [val => !!val || '請輸入帳號', val => (val && val.length <= 10) || '帳號不得超過10碼'] },
        email: {
          id: 'email',
          label: '帳號',
          value: '',
          rules: [
            v => !!v || 'E-mail is required',
            v => /.+@.+\..+/.test(v) || '確認您的帳號是否為email格式'
          ]
        },
        password: { id: 'password', label: '密碼', value: '', rules: [val => !!val || '請輸入密碼'] },
        mem_name: {
          id: 'mem_name',
          label: '姓名',
          value: {
            surname: '',
            givenname: ''
          },
          rules: {
            surname: [val => val.length < 10 || '姓不得超過9個字'],
            givenname: [val => val.length <= 20 || '名不得超過20個字']
          }
        },
        profile: { id: 'profile', label: '身分', value: 'member', rules: [val => !!val || '請選擇身分'] },
        gender: { id: 'gender', label: '性別', value: '', options: [{ text: '女', value: 'F' }, { text: '男', value: 'M' }], rules: [val => !!val || '請選擇性別'] },
        remember: { id: 'remember', label: '保持登入狀態', value: false },
        isRegister: false,
        tel: {
          id: 'tel',
          label: '市話',
          value: '',
          rules: [
            val => val.length <= 10 || '市話不得超過10碼',
            val => /^\d+$/.test(val) || '請輸入正確格式，如: 0287874413'
          ]
        },
        mobile: {
          id: 'mobile',
          label: '手機',
          value: '',
          rules: [
            val => val.length <= 12 || '手機號碼不得超過12碼數字',
            val => /^\d+$/.test(val) || '請輸入正確格式，如: 0917456789 或 886917456789'
          ]
        },
        // residence: {
        //   tg: 'residence',
        //   id: 'residence',
        //   label: '居住地',
        //   value: {
        //     city: '', // 縣市
        //     town: '', // 鄉鎮市區
        //     street: '', // 街道路名
        //     lane: '', // 巷
        //     alley: '', // 弄
        //     no: '', // 號
        //     floor: '', // 樓層
        //     room: '', // 室
        //     zipcode: '', // 5碼郵遞區號
        //     zipcode3: '' // 3碼郵遞區號
        //   },
        //   rules: {
        //     city: [val => !!val || '請輸入縣市'],
        //     town: [val => !!val || '請輸入鄉鎮市區'],
        //     street: [val => !!val || '請輸入街道路名'],
        //     lane: [val => typeof val === 'undefined' || val === '' || /^\d+$/.test(val) || '請輸入數字'],
        //     alley: [val => typeof val === 'undefined' || val === '' || /^\d+$/.test(val) || '請輸入弄'],
        //     no: [val => (!!val && val.length <= 7) || '請輸入門牌號(最多7碼)'],
        //     floor: [val => typeof val === 'undefined' || val === '' || /^\d+$/.test(val) || '請輸入樓層'],
        //     room: [val => typeof val === 'undefined' || val === '' || /^\d+$/.test(val) || '請輸入室']
        //   },
        //   isAddr: true
        // },
        profile_authority: {
          id: 'profile_authority',
          label: '您同意PFWAY針對您註冊會員填寫的資料給予充分的授權使用，但僅限於PFWAY因應內部規劃的行銷活動而使用，未經您的同意PFWAY亦不會將您的個人資料流放與第三方使用。',
          value: '',
          rules: [val => !!val || val === 'Y' || '尚未勾選同意授權']
        }
      },
      redirect: {
        success: '/member/main',
        fail: '/'
      },
      addrOpts: {
        city: [],
        town: {},
        street: {},
        zipcode: {}
      },
      searchStreet: ''
    };
  },
  watch: {
    // 監聽 OAutht處理狀態
    '$store.state.oauth.status': {
      handler (newStatus, oldStatus) {
        if (typeof oldStatus === 'undefined') {
          return;
        }
        // init dialog (含成功會直接關閉)
        const _dialog = this.$options.data().dialog;
        // 異常需要顯示
        if (newStatus >= 2) { // 登入處理中
          _dialog.process = true;
          _dialog.persistent = true;
          _dialog.title = '登入驗證中，請稍後...';
          this.$set(this, 'dialog', _dialog);
          return;
        }
        if (newStatus < 0) { // 登入失敗
          _dialog.title = '登入驗證失敗';
          _dialog.msg = this.$store.state.oauth.msg;
          _dialog.btns.close = true;
          this.$set(this, 'dialog', _dialog);
          return;
        }
        // 登入成功
        console.log('登入成功');
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    /**
     * function => store process
     */
    ...mapGetters({
      loggerProfile: 'oauth/loggerProfile'
    }),
    ...mapActions({
      doLogin: 'oauth/doLogin'
    }),
    /**
     * [setFormInputValue] 設定地址內容
     * @param object params
     *    ['tg'] string 地址類型，['residence']:戶籍地址，['mailing']:通訊地址
     *    ['id'] string 欲變更地址物件中的鍵值名稱
     *    ['value'] string 欲變更地址物件中鍵值id的內容值
     *    ['is_flexible] boolean 是否動態新增資料集
     */
    setFormInputValue (params) {
      const _tg = params.tg == null ? '' : params.tg;
      const _id = params.id == null ? '' : params.id;
      const _val = params.val == null ? '' : params.value;
      const _flexible = params.flexible == null || !params.flexible ? false : params.flexible;
      // 是否未傳值
      if (_tg === '' || _id === '' || _val === '') {
        return false;
      }

      // 動態新增資料集
      if (_flexible) {
        if (this.formVars[_tg] == null || this.formVars[_tg][_id] == null) { // 指定鍵值不存在
          return false;
        }
        this.$set(this.forVars[_tg], _id, _val);
        return true;
      }

      // 非動態新增
      if (this.formVars[_tg] == null || this.formVars[_tg].value[_id] == null) { // 指定鍵值不存在
        return false;
      }
      this.$set(this.forVars[_tg].value, _id, _val);
    },
    /**
     * [clickDialogBtn]
     */
    clickDialogBtn (tg = '') {
      console.log(`[MemberLogin][clickDialogBtn] ... tg: ${tg}`);
      let _path = '';
      const _dialog = this.$options.data().dialog;
      switch (tg) {
        case 'home':
          _dialog.isClose = true;
          _path = '/home';
          break;
        case 'login':
          _dialog.isClose = true;
          _path = '/login';
          break;
        case 'close':
          _dialog.isClose = true;
          break;
        case 'abort':
          window.close();
          break;
      }
      this.$set(this, 'dialog', _dialog);
      // redirect
      console.log('path : ' + _path);
      if (_path !== '') {
        console.log('redirect to : ' + _path);
        this.$router
          .push({
            path: _path
          })
          .catch((error) => {
            // Vue-route3.1版本之後，重複點擊or來源目的連結相同會報 error: NavigationDuplicated，要排除不動作即可
            if (error.name !== 'NavigationDuplicated') {
              return error;
            }
          });
      }
    },
    /**
     * [onSubmit]
     */
    onSubmit () {
      // console.log('[login][onsubmit] ...');
      if (this.$refs.loginForm.validate()) {
        const params = {
          caller: this.formVars.profile.value,
          email: this.formVars.email.value,
          password: this.formVars.password.value,
          remember: this.formVars.remember.value,
          redirect: this.redirect.success
        };
        // register 加入會員
        if (this.formVars.isRegister) {
          this.applyRegister(params);
          return;
        }

        // login OAuth
        this.doLogin(params);
      }
    },
    /**
     * [onReset]
     */
    onReset () {
      this.$refs.loginForm.resetValidation();
      this.formVars = this.$options.data().formVars;
    },
    /**
     * [applyRegister] 申請註冊會員
     */
    async applyRegister (params) {
      console.log('[MemberLogin][applyRegister] ...');
      let _dialog = this.$options.data().dialog;
      if (!params || params.caller === null) {
        _dialog.msg = '入參不正確';
        _dialog.btns.close = true;
        this.$set(this, 'dialog', _dialog);
        return false;
      }

      const _errCode = {
        101: '加入會員失敗',
        102: '查無資料',
        105: '加入會員發生異常'
      };

      // 增加入參
      params = {
        ...params,
        ...{
          mem_name: (this.formVars.mem_name.value.surname + '|' + this.formVars.mem_name.value.givenname),
          gender: this.formVars.gender.value,
          // residence: this.formVars.residence.value,
          tel: this.formVars.tel.value,
          mobile: this.formVars.mobile.value
        }
      };
      console.log(params);

      // dialog with progress
      _dialog.process = true;
      _dialog.title = '加入會員處理中，請稍後...';
      this.$set(this, 'dialog', _dialog);

      // call api
      let fail = {};
      let result = null;
      // dialog
      _dialog = this.$options.data().dialog;
      // _dialog.init = true;
      _dialog.immediate = false;
      try {
        // axios api
        result = await apiMemberPostRegister(params);
        console.log(result);
        // 失敗
        if (!result || !result.status) {
          fail = { action: 'error', status: 101, msg: _errCode[101], data: result };
          // alert(`登入失敗，錯誤訊息: [${fail.status}] ${fail.msg}`);
          _dialog.title = _errCode[101];
          _dialog.msg = result.msg;
          _dialog.btns.close = true;
          this.$set(this, 'dialog', _dialog);
          return false;
        }
        if (!result.data) {
          fail = { action: 'error', status: 102, msg: _errCode[102], data: result };
          _dialog.title = _errCode[102];
          _dialog.msg = result.msg;
          _dialog.btns.close = true;
          this.$set(this, 'dialog', _dialog);
          return false;
        }
        // register success
        this.onReset(); // reset form
        // dialog with success
        _dialog.title = '加入會員成功';
        _dialog.msg = '您已成功加入會員。<br />系統將自動寄送開通帳號驗證信件，<br />請您依照信件內的說明開通帳號即可登入使用。<br />謝謝!';
        _dialog.btns.login = true;
        this.$set(this, 'dialog', _dialog);
      } catch (error) {
        fail = { action: 'error', status: 105, msg: _errCode[105], data: error };
        // dialog with error
        _dialog.title = _errCode[105];
        _dialog.msg = error;
        _dialog.btns.close = true;
        console.log('加入會員發生異常');
        console.log(fail);
        this.$set(this, 'dialog', _dialog);
        return false;
      }
    }
  },
  created () {
    console.log('[MemberLogin][created] ... ');
    // // 取得縣市/鄉鎮市區
    // calAddress.getCityTown({
    //   action: '02',
    //   platform: 'frontend',
    //   logger: '_',
    //   caller: 'ct'
    // }, this, 'addrOpts');
  }
};
</script>
<style scoped>
/* .color-opacity {
  opacity: 100 !important;
} */
</style>
