<template>
  <v-dialog
    v-model="dialog.active"
    :persistent="
      !!dialog.process || dialog.persistent == null || dialog.persistent
    "
    :width="dialog.width != null ? dialog.width : ''"
  >
    <v-card class="pa-2" :color="dialog.process ? 'green darken-4' : ''">
      <v-card-title
        class="py-2 justify-center headline card-title-style-cr white--text"
        ><v-chip color="green darken-4" middle class="white--text">{{
          dialog.title
        }}</v-chip></v-card-title
      >
      <v-spacer class="my-3" />
      <template v-if="dialog.process">
        <v-card-title>
          <v-progress-linear indeterminate color="white"></v-progress-linear>
        </v-card-title>
      </template>
      <template v-else>
        <v-card-text v-html="dialog.msg" v-if="!!dialog.msg"></v-card-text>
        <slot name="custom-card-text"></slot>
        <v-card-actions class="mt-4">
          <slot name="custom-card-actions">
            <!-- <v-btn
              color="green darken-1"
              outlined
              @click="$router.push('/login')"
              v-if="dialog.btns.login"
              >返回登入</v-btn
            >
            <v-btn
              color="green darken-1"
              outlined
              @click="$router.push('/home')"
              v-if="dialog.btns.home"
              >返回首頁</v-btn
            >
            <v-btn
              color="green darken-1"
              outlined
              @click="dialog.active = false"
              v-if="dialog.btns.close"
              >關閉</v-btn
            >
            <v-btn
              color="green darken-1"
              outlined
              @click="window.close()"
              v-if="dialog.btns.abort"
              >跳出</v-btn
            > -->
          </slot>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
  <!-- dialog end -->
</template>
<script>
export default {
  props: {
    dialogProps: {
      type: Object,
      default: function () {
        return {
        };
      }
    }
  },
  data () {
    return {
      dialog: {
        init: false,
        active: false,
        isClose: false, // 關閉訊息窗
        process: false,
        persistent: true, // 預設都要點擊按鈕
        title: '',
        msg: '',
        btns: {
          close: false, // 關閉訊息窗
          login: false, // 返回會員燈入頁
          home: false // 返回平台首頁
        }
      }
    };
  },
  watch: {
    // 'dialog.active': {
    //   handler (newVal, oldVal) {
    //     console.log(`[MsgDialog][watch] ... active: ${newVal} / ${oldVal}`);
    //   }
    // },
    /**
     * 監聽 Dialog
     */
    dialogProps: {
      handler (newProps, oldProps) {
        // console.log('[MsgDialog][watch] ... dialogProps');
        // console.log(newProps);
        // console.log(oldProps);
        // if (newProps.active != null && newProps.active !== oldProps.active) {
        //   console.log('active ... ');
        //   this.dialog = this.dialogProps;
        //   return;
        // }

        // 按下關閉按鈕，直接關閉訊息窗
        if (newProps.isClose) {
          this.$set(this, 'dialog', this.$options.data().dialog);
          return;
        }

        // 是否先重置(似乎用不到，原本是在可開啟狀態下快速關閉再重新打開並更換文字內容)
        if (newProps.init != null && newProps.init !== oldProps.init) {
          // console.log('init ... ');
          if (newProps.init) {
            this.$set(this, 'dialog', this.$options.data().dialog);
          }
        }

        // 更新dialog 內容
        const _dialog = newProps;
        _dialog.title = !_dialog.title ? '系統訊息' : _dialog.title;
        _dialog.msg = !_dialog.msg ? '' : _dialog.msg;
        _dialog.process = !_dialog.process ? false : _dialog.process;
        _dialog.persistent = !_dialog.persistent ? false : _dialog.persistent;
        _dialog.btns = !_dialog.btns ? {} : _dialog.btns;
        _dialog.active = true;

        // 立即執行
        if (newProps.immediate == null || newProps.immediate) {
          // console.log('立即執行 ...');
          this.$set(this, 'dialog', _dialog);
          return;
        }
        // 延遲執行
        var _this = this;
        // console.log('延遲執行 ...');
        setTimeout(function () {
          _this.$set(_this, 'dialog', _dialog);
        }, 500);
      },
      deep: true
    }
  },
  methods: {
    // initSet () {
    //   const _initList = {
    //     init: false,
    //     process: false,
    //     active: false,
    //     title: '',
    //     msg: '',
    //     persistent: false,
    //     btns: {}
    //   };
    //   const _keys = Object.keys(_initList);
    //   let i = 0;
    //   let _key = '';
    //   for (i = 0; i < _keys.length; i++) {
    //     _key = _keys[i];
    //     if (!this.dialog || this.dialog[_key] == null) {
    //       this.dialog[_key] = _initList[_key];
    //     }
    //   }
    // },
    /**
     * [showMsgInfor] 統一處理縣市訊息box
     */
    // showMsgInfor () {
    //   console.log('[MsgDialog][showMsgInfor] ... ');
    //   console.log(this.dialog);
    //   // 是否重置
    //   if (this.dialogProps.init != null && this.dialogProps.init) {
    //     this.$set(this, 'dialog', this.$options.data().dialog);
    //     console.log(this.dialog);
    //   }

    //   // // dialog 內容
    //   // if (this.dialog.immediate == null || this.dialog.immediate) {
    //   //     this.dialog.title = this.dialog.title != null ? this.dialog.title : '系統訊息';
    //   //     this.dialog.msg = this.dialog.msg != null ? this.dialog.msg : '';
    //   //     if (this.dialog.process != null) {
    //   //       this.dialog.process = this.dialog.process;
    //   //     }
    //   //     if (param.persistent != null) {
    //   //       this.dialog.persistent = param.persistent;
    //   //     }
    //   //     if (param.btns != null) {
    //   //       this.dialog.btns = param.btns;
    //   //     }
    //   //     this.dialog.active = param.active != null ? param.active : true;
    //   //   } else { // 延遲執行
    //   //     var _this = this;
    //   //     setTimeout(function () {
    //   //       _this.dialog.title = param.title != null ? param.title : '系統訊息';
    //   //       _this.dialog.msg = param.msg != null ? param.msg : '';
    //   //       if (param.process != null) {
    //   //         _this.dialog.process = param.process;
    //   //       }
    //   //       if (param.persistent != null) {
    //   //         _this.dialog.persistent = param.persistent;
    //   //       }
    //   //       if (param.btns != null) {
    //   //         _this.dialog.btns = param.btns;
    //   //       }
    //   //       _this.dialog.active = param.active != null ? param.active : true;
    //   //     }, 500);
    //   //   }
    //   // }
    // }
  },
  created () {
    // console.log('[MsgDialog][created] ... ');
    // Object.assign(this.$data, this.$options.data());
    Object.assign(this.$data.dialog, this.dialogProps);
    // console.log(this.$data);
  }
  // beforeMount () {
  //   console.log('[MsgDialog][beforeMount] ... ');
  // },
  // mounted () {
  //   console.log('[MsgDialog][mounted] ... ');
  //   console.log(this.dialog);
  // },
  // beforeUpdate () {
  //   console.log('[MsgDialog][beforeUpdate] ... ');
  // },
  // updated () {
  //   console.log('[MsgDialog][updated] ... ');
  //   console.log(this.dialog);
  // }
};
</script>
