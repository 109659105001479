<template>
  <BasicLayout :initVars="initVars">
    <template v-slot:layout-mainbox-inner>
      <MemberLoginForm :title="initVars.title" />
    </template>
  </BasicLayout>
</template>

<script>
import BasicLayout from '@layout/basic';
import MemberLoginForm from '@component/form/MemberLogin';

export default {
  components: { BasicLayout, MemberLoginForm },
  data () {
    return {
      initVars: {
        title: 'PFWAY 會員中心'
      }
    };
  }
};
</script>
